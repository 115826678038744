define("discourse/plugins/stemaway-ui-addons/discourse/helpers/eval-level", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.evalLevel = evalLevel;
  function evalLevel(_ref) {
    let [value] = _ref;
    const levelMapping = {
      1: 'Novice',
      2: 'Developing',
      3: 'Proficient',
      4: 'Advanced',
      5: 'Expert'
    };
    const numericValue = parseInt(value, 10);
    return levelMapping[numericValue] || value;
  }
  var _default = _exports.default = (0, _helper.helper)(evalLevel);
});