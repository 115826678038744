define("discourse/plugins/stemaway-ui-addons/discourse/connectors/user-main-nav/aivia-analytics-nav-item", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiviaAnalyticsNavItem extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get isAllowed() {
      const allowedGroup = this.siteSettings.aivia_analytics_allowed_group;
      const groups = this.currentUser.groups || [];
      const isAdmin = this.currentUser.admin;
      const isInAllowedGroup = groups.some(group => {
        if (typeof group === 'string') {
          return group.toLowerCase() === allowedGroup.toLowerCase();
        } else if (group.name) {
          return group.name.toLowerCase() === allowedGroup.toLowerCase();
        }
        return false;
      });
      return isAdmin || isInAllowedGroup;
    }
  }
  _exports.default = AiviaAnalyticsNavItem;
});