define("discourse/plugins/stemaway-ui-addons/discourse/controllers/aivia-analytics/scout", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax"], function (_exports, _controller, _tracking, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScoutController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "jobTitle", [_tracking.tracked], function () {
      return "";
    }))();
    #jobTitle = (() => (dt7948.i(this, "jobTitle"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "companyName", [_tracking.tracked], function () {
      return "";
    }))();
    #companyName = (() => (dt7948.i(this, "companyName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "jobDescription", [_tracking.tracked], function () {
      return "";
    }))();
    #jobDescription = (() => (dt7948.i(this, "jobDescription"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "skillsInput", [_tracking.tracked], function () {
      return "";
    }))();
    #skillsInput = (() => (dt7948.i(this, "skillsInput"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "selectedSkills", [_tracking.tracked], function () {
      return [];
    }))();
    #selectedSkills = (() => (dt7948.i(this, "selectedSkills"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "selectedProjects", [_tracking.tracked], function () {
      return [];
    }))();
    #selectedProjects = (() => (dt7948.i(this, "selectedProjects"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "projectResults", [_tracking.tracked], function () {
      return [];
    }))();
    #projectResults = (() => (dt7948.i(this, "projectResults"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "showProjectPopup", [_tracking.tracked], function () {
      return false;
    }))();
    #showProjectPopup = (() => (dt7948.i(this, "showProjectPopup"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "showResultPopup", [_tracking.tracked], function () {
      return false;
    }))();
    #showResultPopup = (() => (dt7948.i(this, "showResultPopup"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "resultData", [_tracking.tracked], function () {
      return null;
    }))();
    #resultData = (() => (dt7948.i(this, "resultData"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "skillsList", [_tracking.tracked], function () {
      return [];
    }))();
    #skillsList = (() => (dt7948.i(this, "skillsList"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "projectSearchQuery", [_tracking.tracked], function () {
      return "";
    }))();
    #projectSearchQuery = (() => (dt7948.i(this, "projectSearchQuery"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "editableTitle", [_tracking.tracked], function () {
      return "";
    }))();
    #editableTitle = (() => (dt7948.i(this, "editableTitle"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "editableDescription", [_tracking.tracked], function () {
      return "";
    }))();
    #editableDescription = (() => (dt7948.i(this, "editableDescription"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "editableNotes", [_tracking.tracked], function () {
      return "";
    }))();
    #editableNotes = (() => (dt7948.i(this, "editableNotes"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    constructor() {
      super(...arguments);
      this.loadSkills();
      this._boundHandleClickOutside = this.handleClickOutside.bind(this);
      document.addEventListener('click', this._boundHandleClickOutside);
    }
    async loadSkills() {
      try {
        const data = await (0, _ajax.ajax)("/skills.json", {
          method: "GET"
        });
        this.skillsList = this.flattenDeepestSkills(data);
      } catch (error) {
        console.error("Failed to load skills.json");
      }
    }
    flattenDeepestSkills(skillsObject) {
      let result = [];
      function traverse(skill) {
        let nested = [];
        if (skill.sub_skills && skill.sub_skills.length > 0) {
          nested = skill.sub_skills;
        } else if (skill.skills && skill.skills.length > 0) {
          nested = skill.skills;
        }
        if (nested && nested.length > 0) {
          nested.forEach(traverse);
        } else {
          result.push({
            id: skill.id,
            name: skill.name
          });
        }
      }
      if (skillsObject && skillsObject.skills && Array.isArray(skillsObject.skills)) {
        skillsObject.skills.forEach(traverse);
      }
      return result;
    }
    get filteredSkills() {
      let input = this.skillsInput.trim().toLowerCase();
      if (!input) return [];
      return this.skillsList.filter(skill => skill.name.toLowerCase().includes(input));
    }
    get filteredProjects() {
      const query = this.projectSearchQuery.trim().toLowerCase();
      if (!query) return this.projectResults;
      return this.projectResults.filter(project => project.title.toLowerCase().includes(query));
    }
    updateProjectSearch(event) {
      this.projectSearchQuery = event.target.value;
    }
    static #_17 = (() => dt7948.n(this.prototype, "updateProjectSearch", [_object.action]))();
    updateJobTitle(event) {
      this.jobTitle = event.target.value;
    }
    static #_18 = (() => dt7948.n(this.prototype, "updateJobTitle", [_object.action]))();
    updateCompanyName(event) {
      this.companyName = event.target.value;
    }
    static #_19 = (() => dt7948.n(this.prototype, "updateCompanyName", [_object.action]))();
    updateJobDescription(event) {
      this.jobDescription = event.target.value;
    }
    static #_20 = (() => dt7948.n(this.prototype, "updateJobDescription", [_object.action]))();
    updateSkillsInput(event) {
      this.skillsInput = event.target.value;
    }
    static #_21 = (() => dt7948.n(this.prototype, "updateSkillsInput", [_object.action]))();
    updateEditableTitle(event) {
      this.editableTitle = event.target.value;
    }
    static #_22 = (() => dt7948.n(this.prototype, "updateEditableTitle", [_object.action]))();
    updateEditableDescription(event) {
      this.editableDescription = event.target.value;
    }
    static #_23 = (() => dt7948.n(this.prototype, "updateEditableDescription", [_object.action]))();
    updateEditableNotes(event) {
      this.editableNotes = event.target.value;
    }
    static #_24 = (() => dt7948.n(this.prototype, "updateEditableNotes", [_object.action]))();
    isSkillSelected(skill) {
      return this.selectedSkills.some(s => s.id === skill.id);
    }
    static #_25 = (() => dt7948.n(this.prototype, "isSkillSelected", [_object.action]))();
    selectSkill(skill, event) {
      event.stopPropagation();
      if (this.selectedSkills.find(s => s.id === skill.id)) {
        this.selectedSkills = this.selectedSkills.filter(s => s.id !== skill.id);
      } else {
        this.selectedSkills = [...this.selectedSkills, skill];
      }
    }
    static #_26 = (() => dt7948.n(this.prototype, "selectSkill", [_object.action]))();
    handleClickOutside(event) {
      const container = document.querySelector('.autocomplete-field');
      if (container && !container.contains(event.target)) {
        this.skillsInput = "";
      }
    }
    static #_27 = (() => dt7948.n(this.prototype, "handleClickOutside", [_object.action]))();
    willDestroy() {
      document.removeEventListener('click', this._boundHandleClickOutside);
      super.willDestroy(...arguments);
    }
    removeSkill(skill) {
      this.selectedSkills = this.selectedSkills.filter(s => s.id !== skill.id);
    }
    static #_28 = (() => dt7948.n(this.prototype, "removeSkill", [_object.action]))();
    async findProjects() {
      try {
        const result = await (0, _ajax.ajax)("/custom_search", {
          method: "GET",
          data: {
            search_query: this.projectSearchQuery || "*",
            category_id: 410
          }
        });
        let projects = result.custom_search || result;
        projects = projects.filter(project => {
          const title = project.title.toLowerCase();
          return !title.includes("readme") && !title.startsWith("about");
        });
        this.projectResults = projects;
        this.showProjectPopup = true;
      } catch (error) {
        console.error("Error fetching projects");
      }
    }
    static #_29 = (() => dt7948.n(this.prototype, "findProjects", [_object.action]))();
    isProjectSelected(project) {
      return this.selectedProjects.some(p => p.id === project.id);
    }
    static #_30 = (() => dt7948.n(this.prototype, "isProjectSelected", [_object.action]))();
    toggleProjectSelection(project) {
      if (this.isProjectSelected(project)) {
        this.selectedProjects = this.selectedProjects.filter(p => p.id !== project.id);
      } else {
        this.selectedProjects = [...this.selectedProjects, project];
      }
    }
    static #_31 = (() => dt7948.n(this.prototype, "toggleProjectSelection", [_object.action]))();
    closeProjectPopup() {
      this.showProjectPopup = false;
    }
    static #_32 = (() => dt7948.n(this.prototype, "closeProjectPopup", [_object.action]))();
    async reviewAndPostJob() {
      if (!this.jobTitle.trim() || !this.companyName.trim() || !this.jobDescription.trim()) {
        alert("Job Title, Company Name, and Job Description are required.");
        return;
      }
      try {
        this.isLoading = true;
        const projectLinks = this.selectedProjects.map(project => project.url).join(", ");
        const skills = this.selectedSkills.map(skill => skill.name).join(", ");
        const payload = {
          job_title: this.jobTitle,
          job_description: this.jobDescription,
          skills,
          project_links: projectLinks,
          model: "main"
        };
        const result = await (0, _ajax.ajax)("/custom_search/scout-request", {
          method: "POST",
          data: payload
        });
        this.resultData = result;
        const parsed = this.parsedResult;
        this.editableTitle = parsed.title;
        this.editableDescription = parsed.description;
        this.editableNotes = parsed.notes;
        this.showResultPopup = true;
      } catch (error) {
        console.error("Error calling scout-request endpoint");
      } finally {
        this.isLoading = false;
      }
    }
    static #_33 = (() => dt7948.n(this.prototype, "reviewAndPostJob", [_object.action]))();
    closeResultPopup() {
      this.showResultPopup = false;
      this.resultData = null;
    }
    static #_34 = (() => dt7948.n(this.prototype, "closeResultPopup", [_object.action]))();
    get parsedResult() {
      if (this.resultData && this.resultData.formatted_response) {
        let rawResponse = "";
        if (typeof this.resultData.formatted_response === 'object') {
          if (this.resultData.formatted_response.raw) {
            rawResponse = this.resultData.formatted_response.raw;
          } else {
            return this.resultData.formatted_response;
          }
        } else {
          rawResponse = this.resultData.formatted_response;
        }
        if (rawResponse.startsWith("```json")) {
          rawResponse = rawResponse.slice(7).trim();
          if (rawResponse.endsWith("```")) {
            rawResponse = rawResponse.slice(0, -3).trim();
          }
        }
        try {
          const parsed = JSON.parse(rawResponse);
          return parsed;
        } catch (e) {
          console.error("Error parsing API result");
          return {
            title: this.jobTitle,
            description: this.jobDescription,
            notes: ""
          };
        }
      }
      return {
        title: this.jobTitle,
        description: this.jobDescription,
        notes: ""
      };
    }
    async postJob() {
      if (!this.editableTitle.trim() || !this.editableDescription.trim()) {
        alert("Job Title and Job Description are required to post a job.");
        return;
      }
      let finalDescription = this.editableDescription;
      if (this.selectedProjects.length > 0) {
        const projectLinks = this.selectedProjects.map(project => `- [${project.title}](${project.url})`).join("\n");
        finalDescription += "\n\n**Relevant Projects:**\n" + projectLinks;
      }
      if (this.editableNotes.trim() !== "") {
        finalDescription += "\n\n**Notes:**\n" + this.editableNotes;
      }
      const topicTitle = `${this.jobTitle} - ${this.companyName}`;
      const payload = {
        title: topicTitle,
        raw: finalDescription,
        tags: this.selectedSkills.map(s => s.name).join(", "),
        category_id: this.siteSettings.job_posting_category_id
      };
      try {
        const postResult = await (0, _ajax.ajax)("/custom_search/post-job", {
          method: "POST",
          data: payload
        });
        alert("Job posted successfully!");
        this.closeResultPopup();
      } catch (error) {
        console.error("Error posting job");
        alert("Error posting job. Please try again later.");
      }
    }
    static #_35 = (() => dt7948.n(this.prototype, "postJob", [_object.action]))();
  }
  _exports.default = ScoutController;
});