define("discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isAllowed}}
    <div class="aivia-analytics-nav-item">
      {{user-nav-item
        model=model
        icon="chart-bar"
        label=(i18n "stemaway_ui_addons.aivia_analytics.aivia_analytics_title")
        link="aivia-analytics"
        restricted=false
      }}
    </div>
  {{/if}}
  
  */
  {
    "id": "hZS2p72N",
    "block": "[[[41,[30,0,[\"isAllowed\"]],[[[1,\"  \"],[10,0],[14,0,\"aivia-analytics-nav-item\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"model\",\"icon\",\"label\",\"link\",\"restricted\"],[[30,0,[\"model\"]],\"chart-bar\",[28,[37,3],[\"stemaway_ui_addons.aivia_analytics.aivia_analytics_title\"],null],\"aivia-analytics\",false]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"div\",\"user-nav-item\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});